:root{
  --swiper-theme-color: #f7f7f8 !important;
}

.stack-container{
  white-space: pre-line;
  min-height: calc(17svh + 35svh);

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

 

}
.firstBox{
    cursor: pointer;
    background-color: aliceblue;
    height: 19svh;
    width:  18svw;
    text-align: center;
    border-radius: 20px;

    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.secondBox{

  
  margin-top: 10px;
  background-color: aliceblue;
  width:  17vw;
  height: 35svh;
  height: 0;
  border-radius: 20px;
  padding: 12px 12px 12px 20px;
  padding: 0px;
  cursor: pointer;
  font-size: calc(0.8svh + 0.8svw);
  font-weight: 500;
  overflow: hidden;


  display: flex;
  justify-content: space-around;
  flex-direction: column;

}
.stack2plus{
  position: absolute;
  bottom: calc(-1.2svh - 0.7svw);
  font-size: max(15px,calc(1.7svw + 1.6svh));
  font-weight: 100;
  color: white;
  background-color: #101729;

  border-radius: 60%;
  animation-name: scaleAnimation;
  animation-iteration-count: infinite; 
  animation-duration: 2s;
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2); 
  }
  10% {
    transform: scale(1); 
  }
  100%{
    transform: scale(1); 
  }
}
.divStack{
  white-space: pre-line;
}
.firstBoxTitle{
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    flex-direction: column;
    height: 100%; /* Ensures full height alignment */
    font-size: calc(0.9svh + 0.8svw);
    font-weight: 600;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}
.subTitle{
  margin-top: calc(0.4svh + 0.4svw);
  font-weight: 400;
  font-size: calc(0.6svh + 0.6svw);
  text-align: start;
}
.imgStack{

  /* width: 180px; */
    width: 4vw;
    min-width: 40px;
    /* margin-left: 130px; */
    margin-top: -15%;

}

.addedText{
  font-size: calc(0.7svh + 0.7svw);
}

@media screen and (min-width: 601px) {



.secondBox:hover,
.secondBox:hover + .firstBox {
  border: 3px solid black;
}

.secondBox:hover ~ .firstBox {
    border: 3px solid black;
  }
}

.secondBox, .firstBox{
  box-sizing: border-box;
  list-style: none;
}



.StackView2Div{
  margin-bottom: calc(0.7svh + 0.7svw);
}








@media (max-width: 600px) {




  .stack-container{
    margin-top: 5svh;
  }
  .firstBox{
    width: 60svw;
  }
  .imgStack{
    width: calc(10svw  + 3svh);
    margin-top: calc(-5svw x- 1svh);
  }
  .firstBoxTitle{
    font-size: calc(0.8svh + 3.4svw);
    width: 90%;
}

.subTitle{
  font-weight: 400;
  font-size: calc(0.65svh + 1.9svw);
  text-align: start;
}
  .secondBox {
    text-align: center;
    font-size: calc(1svh + 3.2svw);
    width: 60svw;
}


.addedText{
  font-size: calc(0.8svh + 2.3svw);
}

}
 
