#myVideo{
    height: 100svh;
    width: 100svw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    filter: blur(1px);
    min-height: -webkit-fill-available;
}
@font-face {
  font-family: 'sailor'; /* Choisissez un nom de police */
  src: url('../fonts/ugppme/sailors-1.otf') format('woff2'),
  url('../fonts/ugppme/sailors-1.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

#landing-container{
  width: 100svw;
  overflow-x: hidden;
}

#ugpmImg{

    height: 20svh;
    margin-bottom: 3svh;
  }
.waveBottomLandingContainer{
  position: absolute;
  z-index: 23;
  bottom: -0.4svh;
  overflow-x: hidden;
  height: 8.3svh;
  width: 100svw;
  
}
.ContentLandingText{
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    margin-left: 5svw;
    margin-top: 5svh;
}
#landing-container{
    overflow: hidden;
    max-height: 100svh;
    background-color: green;
}
.content-Title{
    color: white;
    z-index: 2;
    font-size: calc(2.2svh + 2.2svw); 
    font-weight: 600;
    width: 100%;
    
}
.content-Text{
    margin-top: 5%;
    width: 40svw;
    font-weight: 500;
    font-size: 25px;
}
.content-Text span{
    text-decoration: underline;
    text-decoration-color: #e35400;
    text-decoration-thickness: 3px;
    text-underline-offset: 0.2em;
    text-decoration-skip-ink: none;
}
#waveFooter{
    position: relative;
    z-index: 3;
    bottom: 0;
}


.gradient-button {
    display: inline-block;
    border: 3px  solid white;

    padding: max(6px, calc(0.8svh + 0.8svw));
    font-size: max(8px, calc(0.8svh + 0.8svw));
    left: 80svw;
    top: 80svh;

    background: linear-gradient(to right, #9e42e4, #f15116);
    color: #ffffff;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    z-index: 3;
    overflow: hidden;
    cursor: pointer;
    transition: background 0.3s ease;
    -webkit-animation: glowing 4500ms infinite;
    -moz-animation: glowing 4500ms infinite;
    -o-animation: glowing 4500ms infinite;
    animation: glowing 4500ms infinite;
  }
  
  .gradient-button:hover {
    background: linear-gradient(to right, #f15116, #9e42e4);
    animation: shake 0.5s linear;
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  

  @-webkit-keyframes glowing {
    0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  }
  
  @-moz-keyframes glowing {
    0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  }
  
  @-o-keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  }
  
  @keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  }


  .burger-menu {
    position: absolute;
    z-index: 3;
    display: inline-block;


    left: calc(95svw - 32px);
    top: 5svh;
  }
  
  .burger-toggle {
    display: none;
  }
  
  .burger-icon {
    cursor: pointer;
    padding: 10px;
  }
  
  .burger-icon span {
    display: block;
    width: 40px;
    height: 5px;
    background-color: #f15116;
    margin-bottom: 5px;
    transition: transform 0.3s ease;

  }
  

  
  .menu {
    position: absolute;
    right: -2%;
    padding: 20px 10px 20px 10px;

    border: 1px solid #f15116;
    opacity: 0;
    pointer-events: none;
    z-index: 5;
    transition: opacity 0.3s ease;
    border-radius: 20px;
    margin-left: -10svw;
    margin-top: -1svh;
    overflow: hidden;
  }
.backgroundMenu{
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(to right,#9b542e,  #f15116);
  filter: opacity(0.8);
  width: 200%;
  height: 200%;
  left: 0;
}
  .menu li{
    font-size: 21px;
  }
  
  .burger-toggle:checked ~ .menu {
    pointer-events: auto;
  }
  
  .menu ul {
    list-style: none;
    padding: 5px;
    margin: 0;
    width: max-content;
    position: sticky;
    z-index: 1;
  }
  

  
  .menu a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s ease;
  }
  
  .menu a:hover {
    text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: #e35400;
    text-decoration-thickness: 3px;
    text-underline-offset: 0.2em;
    text-decoration-skip-ink: none;
  }



.wave{
    position: absolute;
    z-index: 3;
    bottom: 0;
}


@media (max-width: 1600px) {
    .content-Title {
      font-size: calc(1.9svh + 1.9svw);;
    }
    .content-Text {
      font-size: calc(1.15svh + 1.15svw);
    }
  }
@media (max-width: 1200px) {
.content-Title {
    /* font-size: 40px; */
}
.content-Text {
    /* font-size: 22px; */
    margin-top: 5%;
}

.gradient-button {
  font-size: 20px;
}

}



@media (max-width: 1000px) {
    .content-Title {
      /* font-size: 35px; */
    }
    .content-Text {
      /* font-size: 20px; */
    }
    .gradient-button{
        font-size: 11px;
    }
    .gradient-button {
      font-size: 15px;
    }
    .gradient-button {
      left: 75svw
    }
  }
  @media (max-width: 900px) {
    .content-Title {
      /* font-size: 32px; */
    }
    .content-Text {
      /* font-size: 18px; */
    }
        .gradient-button {
      left: 72svw;
    }
  }
  @media (max-width: 600px) {
    #myVideo {
        height: 100%;
        width: 100%;
      }
      .gradient-button{
        font-size: 10px;
    }

    .gradient-button {
      left: 60svw;
    }

    #ugpmImg{
      height: 13svh;
  }

  .menu li{
    font-size: 14px;
  }
  .menu {
    padding: 10px 5px 10px 5px;
  }
  .content-Title{
    margin-top: 8svh;
    width: 80%;
  }
  
  }
  @media (max-width: 500px) {
    .content-Title {
      /* font-size: 30px; */
    }
    .content-Text {
      font-size: 18px;
      width: 50svw;
    }
  }

  @media (max-width: 450px) {
    .content-Title {
      /* font-size: 20px; */
    }
    .content-Text {
      font-size: 14px;
      width: 62svw;
    }
  }
    @media (max-width: 400px) {

        .content-Text {
          /* font-size: 16px; */
        }
  }

