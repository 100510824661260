@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
html, body {
    overflow-x: hidden;
}
div{
    font-family: 'sailor', sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
  }

.Home{
    display: grid;
    width: 100svw;
    overflow: hidden;
}
html{scroll-behavior:smooth}
