.View3Container {
    height: 110svh;
    width: 100svw;
    margin: 0;
    padding: 0;
    position: relative;
    background: #f0f0f0;
    z-index: 40;
}

.rdvButton{
    position: absolute;
    top: 88svh;
}

.HeaderView3{
    margin-top: 10svh;
    margin-left: 5svw;
    position: relative;
    z-index: 10;
    font-weight: 600;
    font-size: calc(1.5svh + 1.5svw); 
}
.SubHeaverView3{
    margin-left: 5svw;
    font-size: calc(0.9svw + 0.9svh);
    width: 80%;
    position: relative;
    z-index: 10;
    margin-bottom: 5svh;
    font-weight: 400;

}
.highleted{
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 0.2em;
    text-decoration-skip-ink: none;
}

.SubHeaverView3{
    margin-top: 7svh;
}

.SubHeaverView3 span{
    text-decoration: underline;
    text-decoration-color: #e35400;
    text-decoration-thickness: 3px;
    text-underline-offset: 0.2em;
    text-decoration-skip-ink: none;
}

.View3StackContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 92%;
    margin-left: 5svw;
    position: relative;
    z-index: 5  ;
}


.waveContainerTopView3 {
    position: absolute;
    height: 8svh;
    top: 0;
    width: 300svw;
    
}

.waveBottomView3 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 200svw;
    height: 10svh;

}

.waveBottomView3Container{
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
}
  
.View3StackContainer .swiper-button-prev,
.View3StackContainer .swiper-rtl,
.View3StackContainer .swiper-button-next {
  color: black;
}

.View3StackContainer .swiper-pagination-bullet-active{
    background-color: black;
}



@media (max-width: 1650px) {
    .HeaderView3{
    font-size: calc(1.9svw + 1.9svh);
    }
.SubHeaverView3 {
    width: 80%;
}

  }
  @media (max-width: 600px) {
    .View3Container .swiper-slide{
        display: flex;
        justify-content: center;

    }

    .SubHeaverView3 {
    font-size: calc(1svw + 1svh);
    width: 95%;
    }


    .SubHeaverView3{
        margin-top: 0;
        margin-bottom: 4svh;
        width: 80%;

    }

    .rdvButton {
        position: absolute;
        top: 90svh;
        left: 55svw;
    }

    
    

  }

