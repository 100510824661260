.view5BiggestContainer{
    /* background-color: #101729; */
    background-color: #000000;
    height: 114svh;
    width: 100svw;
    position: relative;
}

@media (max-width: 600px) {
    .view5BiggestContainer {
      height: 120vh;
    }
  }
  

.waveView5{
    position: absolute;
    top: 0;
    width: 300svw;
    height: 5svh;
}


.waveView5Bot{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 300svw;
    height: 10svh;
}
.waveView5BotContainer{
    position: absolute;
    bottom: 0;
    z-index: 1;
}



.waveView5Title{
    color: white;
}

.view5CardsContainer{
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: center;
    row-gap: 1svh;
    width: 95%;
    margin-left: 5svw;
    height: 45svh;


}
@media (max-width: 600px) {
    .view5BiggestContainer {
      height: 120vh;
    }

    .view5CardsContainer{
        /* display: flex; */
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-self: center;
        align-items: center;
        margin: 0;
        width: 100svw;
    
    }
  }
  
@media (max-width: 600px) {
    .view5CardsContainer {
      margin-bottom: 6.5svh;
    }
  }
  

.testtest{
    width: 20%;
}

.view5BiggestContainer .swiper-button-next,.view5BiggestContainer .swiper-button-prev:after  {
    color: white !important;
  }

@media (min-width: 600px) {
    .view5BiggestContainer .swiper-button-prev{
        display: none;
    
    }

    .view5BiggestContainer .swiper-pagination{
        bottom: 0svh !important;
    }
}
  

.view5BiggestContainer .swiper-pagination-bullet{
    opacity: unset;
}

@media (max-width: 600px) {
    .view5BiggestContainer .swiper-pagination{
        bottom: 12svh !important;
    }
    .view5CardsContainer .swiper-button-next,
    .view5CardsContainer .swiper-button-prev
    {
        top:40% !important; 
    }
}
  

.view5BiggestContainer .swiper{
    height: 39svh;
}
