    .StackView3Container{
    height: 48svh;
    width: 19svw;
    border-radius: calc(1svw + 1svh);
    color: white;
    position: relative;
    padding-right: calc(1svw);
    padding-left: calc(1svw);
    padding-top: calc(1svh);
    padding-bottom: calc(1svh);
    transition: transform 0.2s ease-in-out; /* Add transition for smoother effect */
    /* padding: 12px 12px 12px 20px; */



}


.StackView3Container:hover {
  animation: vibrate 3s ease infinite;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translate(-2px, -2px);
  }
  20% {
    transform: translate(2px, 2px);
  }
  30% {
    transform: translate(0px);
  }
  100% {
    transform: translate(0px);
  }
}


.StackView3Header{
    font-size: calc(0.9svh + 0.8svw);;
    font-weight: 600;
    height: 8svh;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.StackView3SubHeader{
    font-size: calc(0.65svw + 0.65svh);
    margin-top: 30px;
}
.arrowStack3{
    position: absolute;
    left: 18svw;
    top: 2svh;
    font-size: calc(2svh + 2svw);
    z-index: 40;
}

.white-disk-3 {
    position: absolute;
    width: calc(1.6svh + 1.6svw);
    height: calc(1.6svh + 1.6svw);
    border-radius: 50%;
    background-color: white ;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(1.7svh + 1.7svw);

    left: calc(16.5svw);
    top: calc(1svh);
  }

/*
@media (max-width: 1650px) {
    .StackView3Container {
        height: 420px;
        width: 320px;
        border-radius: 17px;
    }
    .arrowStack3 {
        left: 92%;
        top: 40%;
        font-size: 90px;
    }
    .StackView3Header {
        font-size: 32px;
    }
    .StackView3SubHeader {
        font-size: 22px;
    }
}

@media (max-width: 1500px) {
    .StackView3Container {
        height: 390px;
        width: 290px;
        border-radius: 17px;
    }
    .arrowStack3 {
        left: 90%;
        top: 40%;
        font-size: 90px;
    }
    .StackView3Header {
        font-size: 28px;
    }
    .StackView3SubHeader {
        font-size: 20px;
    }
}

@media (max-width: 1310px) {
    .StackView3Container {
        height: 280px;
        width: 160px;
        border-radius: 17px;
    }
    .arrowStack3 {
        left: 90%;
        top: 35%;
        font-size: 55px;
    }
    .StackView3Header {
        font-size: 17px;
    }
    .StackView3SubHeader {
        font-size: 13px;
    }
    .StackView3Container{
        padding: 12px;
    }
    .white-disk-3 {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        left: 78%;
        top: 8%;
        font-size: 30px;
      }
    
}


@media (max-width: 820px) {
    .StackView3Container {
        height: 220px;
        width: 120px;
        border-radius: 12px;
    }
    .StackView3Container{
        padding: 16px;
    }
    .arrowStack3 {
        left: 90%;
        top: 35%;
        font-size: 40px;
    }
    .StackView3Header {
        font-size: 9px;
        width: 50%;
    }
    .StackView3SubHeader {
        font-size: 10px;
        margin-top: 2px;
    }

    .white-disk-3 {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        left: 78%;
        top: 3%;
        font-size: 20px;
      }
    
}*/

@media (max-width: 600px){
    .arrowStack3{
        display: none;
    }
    .StackView3Container{
        margin-bottom: 40px;
    }
    .StackView3Container{
        width: 70svw;
        height: 50svh;
    }
    .StackView3Header {
        font-size: calc(2.4svw + 2.4svw);
        text-align: center;
    }
    .white-disk-3 {
        width: calc(3.6svh + 3.6svw);
        height: calc(3.6svh + 3.6svw);
        font-size: calc(3svw + 3svh);
        left: 36svw;
        top: 2svh
    }
    .StackView3SubHeader {
        font-size: calc(1.4svw + 1.4svh);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
} 