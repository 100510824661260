  .card5BiggerBiggestContainer {
    position: relative; 
    width: 18svw;
    height: 35svh;
    border-radius: calc(0.8svw + 0.8svh);
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;

  }
.iconTitleCOntainer{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: calc(1svw + 1svh);
  font-size: calc(4svh + 6svw);
  height: 16%;
  width: 18svw;
}

.card5BiggerBiggestContainer div div{
  font-size: calc(1svh + 1.1svw);  
}

.card5BiggerBiggestContainer img{
  object-fit: cover;
  width: 100%;
  height: 30%;
  filter: brightness(0.7);
  border: 2px black solid;

  width: 18svw;
  height: 35svh;

}
.iconTitleCOntainer img{
  width: 2svw;
  max-height: 80%;
  height: unset;
  object-fit: cover;
  filter: none;
  border: none;
}

.cardContent {
  font-size: calc(0.7svh + 0.7svw);
  padding: 3%;

  position: absolute;
  text-align: center;
  color: white;
  display: none;

  font-family:Arial, Helvetica, sans-serif;
}

.card5BiggerBiggestContainer:hover .hm{
  filter: brightness(0.1);

}
.card5BiggerBiggestContainer:hover .cardContent{
  display: block;
}

.card5BiggerBiggestContainer:hover {
  overflow: visible;
}
.clickMe{
  text-align: center;
  font-size: calc(0.6svh + 0.8svw);
  width: 100%;
  /* border-radius: 0 0  calc(0.8svw + 0.8svh) calc(0.8svw + 0.8svh); */
  display: none;
}

.card5BiggerBiggestContainer:hover .clickMe{
  display: block;
  position: absolute;
  bottom: 0;
}

.waveView5Title{
  color: white;
  font-size: calc(2.2svh + 2.2svw);
  margin-bottom: 6svh;
  margin-left: 5svw;
  margin-top: 5svh;
  font-weight: 600;
  /* text-align: center; */
}
.mainCard{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.mainCard img{
  object-fit: cover;
  filter: brightness(1);
  border: none;
  width: unset;
  height: 35%;
}
.mainCard div{
  font-size: calc(1svh + 1svw);
  color: whitesmoke;
  text-align: center;
}
@media (max-width: 600px){

  .hm{

    /* width: 60svw !important;
    height: 40svh !important; */

    width: min(70svw, 70svh) !important;
    height: min(70svw, 70svh) !important;
  
  }


  .card5BiggerBiggestContainer {
    margin-top: 5svh;
    /* width: 60svw ;
    height: 40svh; */
    width: min(70svw, 70svh) !important;
    height: min(70svw, 70svh) !important;

    border-radius: calc(0.8svw + 0.8svh);
    margin-left: auto;
    margin-right: auto;
}
  .mainCard div {
    font-size: calc(2svh + 2svw);
    color: whitesmoke;
    text-align: center;
  }
  .view5BiggestContainer .swiper{
    height: calc(50svh + 12svw);
  }
  .cardContent {
    font-size: calc(1.2svh + 1.0svw);
    padding: 3%;
}
  .card5BiggerBiggestContainer .swiper-button-next,.card5BiggerBiggestContainer .swiper-button-prev:after  {
    color: #f65e20 !important;
  }

  .card5BiggerBiggestContainer div div {
    font-size: calc(1.5svh + 1.5svw);
  }
  .iconTitleCOntainer img{
    width: 6svw;
  }
  .iconTitleCOntainer{
    width: 60svw;
  }
}

