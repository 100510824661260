.questionContainer{
    font-size: max(14px, calc(0.9svh + 0.9svw));
    display: flex;
    justify-content: space-between;
}
.expand{
    /* position: absolute; */
    width: 3%;
    color: white;
    text-align: center;
    min-width: 10px;
    left: 97%;
    cursor: pointer;
}
.rotate180 {
    transition: transform 0.6s ease; 
    transform: rotate(180deg);
  }
.rotate0 {
    transition: transform 0.6s ease; 
    transform: rotate(0deg);
  }

.displayed {
  height: auto; 
  max-height: 1000px; 
  transition: max-height 2s ease; 
}

.hidden {
  max-height: 0;
  overflow: hidden; 
  transition: max-height 0.2s ease; 
}

.response{
    padding-left: 0.8svw;
    font-size: max(10px, calc(0.9svh + 0.9svw));
    overflow: hidden;
    height: 0;
    font-weight: 500;
}
@media (max-width: 600px) {
  .response{
    font-size: max(14px, calc(1.05svh + 1.05svw));
}
}