.View4Container {

  background-color: #f0f0f0;
  height: 115svh;
  width: 100svw;
  position: relative;
  
}


.View4StackContainer{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  height: 80%;
}
.HeaderView4{
  font-size: calc(1.9svw + 1.9svh);
  margin-top: 10svh;
  margin-left: 5svw;
  font-weight: 600;
}
.SubHeaverView4{
  font-size: calc(0.9svw + 0.9svh);
  margin-left: 5svw;
}
.waveContainerTopView4 {
  position: absolute;
  height: 200px;
  z-index: 0;
  top: 0;
  width: 100vw;
}

.View4StackContainer .swiper-pagination{
  background-color: aquamarine;
}

.waveTopView4{
  position: absolute;
  height: 8svh;
  top: 0;
  width: 200svw;
}

.waveBottomView4{
  position: absolute;
  height: 8svh;
  top: 100svh;
  width: 400svw;
}

.waveBottomView4Containerr{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 300svw;
  height: 10svh;
}