.StackView4Container{
    height: 60svh;
    width: 28svw;
    position: relative;
    background-image: linear-gradient(to right,     #dddad8 , #ffffff);
    border-radius: calc(2svw + 2svh);
    cursor: pointer;
    filter: brightness(0.7);
}
.Stack4Img{
    height: 45svh;
    width: 28svw;
    object-fit: cover;
    position: absolute;
    filter: brightness(0.6);   
    border-radius: calc(1svw + 1svh); 
    z-index: 1;
}
.StackView4Container:hover {
    filter: brightness(1);
}
.stack4TextContainer{
    position: absolute;
    color: white;
    z-index: 4;
    height: 44svh;
    width: 26svw;
    padding-left: 1svw;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.innerText{
    font-size: calc(0.7svh + 0.7svw);
    max-width: 38svw;
}
.titleStackView4{
    font-weight: 300;
    font-size: calc(1.5svh + 1.5svw);
    max-width: 38svw;
}
.outerText{
    position: absolute;
    color: black;
    z-index: 4;
    width: 26svw;
    padding-left: 1svw;
    margin-top: 46svh;
    font-size: calc(0.75svh + 0.7svw);
    max-width: 38svw;
}

@media (max-width: 600px) {
    .StackView4Container{
        width: 38svw;
    }
    .Stack4Img{
        width: 38svw;
    }
    .titleStackView4 {
        font-size: calc(1.6svh + 1.6svw);
        width: 38svw;
    }
    .innerText{
        font-size: calc(0.8svh + 0.8svw);
    }
    .outerText{
        font-size: calc(0.8svh + 0.8svw);
    }
}