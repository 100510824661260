.View2Container{


    height: 108svh;
    width: 100svw;
    margin-top: 100svh;
    background: linear-gradient(to bottom, #f66128, #101729);
    /* background-image: url('../Assets/view2/Background.jpg'); */
    background-size: cover;
    position: relative;

  }

#stacksContainer{
    width: 90%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}
#titleView2{
    color: white;
    font-size: calc(1.95svh + 1.9svw); 
    margin-bottom: 0svh;
    margin-left: 5svw;
    margin-top: 8svh;
    font-weight: 600;
    width: 80%;
}
.View2Msg{
    width: 60%;
    color: white;
    font-size: calc(1.2svh + 1.2svw); 
}
.View2Footer{
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    align-items:center;
    margin-left: 5svw;
    justify-content: space-between;
    width: 90%;
    
}
.waveBottomLanding{
  height: 8svh;
  position: absolute;
  bottom: -0.2svh;
  left: 0;
  z-index: 10;
  width: 200svw;
}
.gradient-color-2 {
    border: 3px  solid white;
    display: inline-block;

    padding: max(6px, calc(0.8svh + 0.8svw));
    font-size: max(9px, calc(0.9svh + 0.9svw));
    left: 80svw;
    top: 80svh;
    position: absolute;

    background: linear-gradient(to right, #9e42e4, #f15116);
    color: #ffffff;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    z-index: 3;
    overflow: hidden;
    cursor: pointer;
    transition: background 0.3s ease;
    -webkit-animation: glowing 4500ms infinite;
    -moz-animation: glowing 4500ms infinite;
    -o-animation: glowing 4500ms infinite;
    animation: glowing 4500ms infinite;
  }
  
  .gradient-color-2:hover {
    background: linear-gradient(to right, #f15116, #9e42e4);
    animation: shake 0.5s linear;
  }

.View2Container .swiper-pagination-bullet {
  opacity: .8;
}

  @media (max-width: 2500px) {
    #stacksContainer {
      width: 90%;
    }

  }

  

    
  @media (max-width: 1980px) {



  }

  @media (max-width: 1200px) {
    .gradient-color-2 {
      font-size: 20px;
    }
    

  }



  @media (max-width: 1100px) {


    .gradient-color-2 {
      font-size: 15px;
    }
    .View2Msg {
      font-size: 16px;
    }

    #stacksContainer{
      justify-content: space-evenly;
      column-gap: 10px;

    }

  }
  @media (max-width: 1000px) {


  
  .gradient-color-2 {
    font-size: 15px;
  }
  .gradient-color-2 {
    left: 75svw
  }

  }

  @media (max-width: 900px) {
    .gradient-color-2 {
      left: 72svw;
    }
    
  }
  @media (max-width: 850px) {

    .View2Msg {
      font-size: 14px;
    }


  }

  @media (max-width: 700px) {

    .View2Msg {
      font-size: 12px;
    }

    .View2Footer{
      margin-top: 1vh;
    }

  }



@media (max-width: 600px) {

  .stack2plus {
    position: absolute;
    bottom: 0.1svh;
    font-size: max(20px,7svw) !important;
    color: #f66128;
}


  #titleView2 {
  }
  .View2Footer{
    width: 95%;
  }
  .View2Msg {
    font-size: calc(1.25svh + 1.25svw);
}
.gradient-color-2 {
  /* padding: 10px;
  font-size: calc(1svh + 1svw);
  border-radius: 15px;
  margin-right: 5svh; */
  left: 65svw;
  top: unset;
  bottom: 10px;
  font-size: 10px;
}
.stack-container{
  margin-top: 2svh;
  margin-bottom: 10svh;

} 




} 



@media screen and (orientation:portrait) {
  .View2Footer {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    row-gap: 20px;
  }.View2Msg {
    width: 90%;
}

}
