.ViewFormContainer{
    /* background-color: #f0f0f0; */
    background-color: #ffffff;
    z-index: 1;
    position: relative;
    top:0;
}




.formulairePhrase{
    margin-top: 8svh;
    margin-left: 5svw;
    font-weight: 600;
    z-index: 3;
    position: relative;
    font-size: calc(1.9svw + 1.9svh);
}

.waveContainerTopViewForm {
    position: absolute;
    height: 4svh;
    top: 0;
    width: 100svw;
    z-index: 2;
    background-color: #f0f0f0;

}
.formContainer{
    width: 95%;
    
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;

}


@media (min-width: 601px) {

.formContainer div{
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
}

}
.hs-form__virality-link{
    display: none !important;
}
.waveContainerDownViewForm{
    z-index: 4;
}
  
.waveContainerDownViewForm img {
width: 200svw;
vertical-align: bottom;
height: 12svh;
margin-bottom: -5svh;
z-index: 4;
position: absolute;
bottom: 0;

background: #ffffff;
}

.FooterFish{
    width: 10svw;
}