.login-wrapper {

  height: 100svh;
  width: 100svw;
}
.login-wrap{
  background-color: white;
  width: 90svw;
  height: 90svh;
  top: 5svh;
  left: 5svw;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;  z-index: 2;
  position: absolute;

  display: grid;
  grid-template-columns: 1fr 2fr;
}
.error{
  color: #ff0000;

}
.login-wrap>* {
  font-size: calc(1.5svw + 1.5svh);
}
input[type="text"],
input[type="password"],
input[type="submit"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}
input[type="submit"] {
    background-color: #332d2d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
}
input{
  font-size: calc(1svw + 1svh);
}
input[type="submit"]:hover {
    background-color: #332d2d;
}
.leftLogin{
  grid-column: 1;
  background-color: white;
  background-image: url('../../Assets/Login/connect.jpg');
  background-size: cover;
  border-radius: 20px 0px 0px 20px;

}
.rightLogin{
  grid-column: 2;
  border-radius: 0px 20px 20px 0px;

  display: flex;
  justify-content: center;
  align-self: center;

}
.subrightlogin{
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  row-gap: 30px;
}
.LogoContainerLogin{
  display: flex;
  align-items: center;
  
}
.copyrightLogin{
  color: #555;
  font-weight: 200;
  font-size: calc(0.5svw + 0.5svh);

}
.LogoContainerLogin img{
  width: 10%;
  height: auto;
}

.login-biggest-wrap{
  background: url('../../Assets/Login/fishs.png');
  background-size: cover;
  height: 100svh;
  width: 100svw;
  position: absolute;
  filter: blur(2px);
  z-index: 1;
}