.FooterContainer{
    height: 45svh;
    width: 100svw;


    /* background-color: #101728; */
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
    position: relative;

}
.firstDivFooter{
    width: 100svw;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;


}
.FooterContainerImg{
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 60px;
    margin-top: -10vh;
}

.IconsContainer{
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}
.FooterContainerContact span{
    color: white;
}
.FooterContainerContact{
    display: flex;
    justify-self: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: calc(1svh + 1svw);
    margin-top: -10vh;
    align-items: center

}
.FooterFish{
    min-width: 50px;
}
.FooterContainerContact>.Contact{
    font-size: 60px;
}

@media (max-width: 1000px){
    .FooterContainerImg{
        font-size: 40px;
    }
    .FooterContainerContact{
        font-size: 20px;
    }
    .IconsContainer{
        font-size: 30px;
        width: 40%;
    }
    .FooterFish{
        width: 80%;
    }
    .FooterContainerContact>.Contact{
        font-size: 50px;
    }
}

@media (max-width: 1000px){
    .FooterContainerImg{
        font-size: 30px;
    }
    .FooterContainerContact{
        font-size: 15px;
    }
    .IconsContainer{
        font-size: 25px;
    }
    .FooterFish{
        width: 80%;
    }
    .FooterContainerContact>.Contact{
        font-size: 40px;
    }
}

@media (orientation: portrait) {
    .firstDivFooter{
        flex-direction: column;
        height: 100%;
    }
    .FooterContainerImg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .FooterContainerContact > .Contact {
        font-size: calc(2svh + 2svw);
      }
    .IconsContainer {
        font-size: calc(2svh + 2svw);
    }
    .FooterFish{
        margin-top: 2svh;
    }
    .IconsContainer {
        width: 100%;
    }
    .firstDivFooter {
        justify-content: space-evenly;
    }
}



  @keyframes moveElement1 {
    0%, 20%, 100% {
      transform: translateY(0);
    }
    10% {
      transform: translatey(20px);
    }
  }
  @keyframes moveElement2 {
    0%, 50%, 100% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-20px);
    }
  }

  