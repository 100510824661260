.circle-containah{
    width: 100svw;
    overflow-x: hidden;
    position: absolute;
    margin-top: 96svh;
    z-index: 3;
}

.circle-zwin{
    width: 30px;
    height: 30px;
    background: linear-gradient(to bottom, blue, purple );
    border-radius: 50%;
}

.horizontal-line {
    width: 200vw;
    height: 2px;
    background-color: red;
    margin-left: -40px;
  }

.circle {
    position: relative;
    width: calc(2svh + 4svw);
    height: calc(2svh + 4svw);
    border-radius: 50%;
    overflow: visible;
    border: 1px solid black;
    z-index: 100;
}
  
.emoji {
        position: absolute;
        font-size: 20px;
        background-color: aquamarine;
        border: 1px solid black;
        border-radius: 5px;
}

.diamond {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

}

.clown {
top: 50%;
left: 100%;
transform: translate(-50%, -50%);
}

.money {
bottom: 0;
left: 50%;
transform: translate(-50%,  50%) rotate(45deg);
}

.hand {
top: 50%;
left: 0;
transform: translate(-50%, -50%);
}
.fish{
    top: calc(50% - 12px);
    left: -12px;
    transform: scaleX(-1);

}
.waveSeperator{
    height: 5svh;
    width: 200svw;
    position: absolute;
    z-index: 1;
}
.waveSperatorContainer{
    top: 0svh;
    left: -100svw;
    position: absolute;
}
.waveSperatorContainerB{
    top: 0.2svh;
    left: 103svw;
    position: absolute;
}


