.View6Container{
    height: 100svh;
    background: linear-gradient(to bottom, #f66128, #101729);
    padding-left: 5svw;
    padding-top: 10svh;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    color: white;
}

.View6Container h1{
    font-size: calc(1.9svw + 1.9svh);
}

.View6Container h2{
    font-size: max(calc(1svw + 1svh),10px);

}
